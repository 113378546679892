.generated-legend ol:hover {
  @apply overflow-visible w-full z-10;
}

.generated-legend ol li.truncate:hover {
  @apply overflow-visible underline bg-secondary-100;
}

.generated-legend ol li.truncate:hover p {
  @apply overflow-visible;
}
