@import 'flatpickr/dist/themes/airbnb.css';

.datefield > .flatpickr-calendar {
  top: 20% !important;
  left: 0px !important;
  transform: translateY(-20%);
}

.datefield > .arrowBottom::before {
  border-top-color: transparent !important;
}

.datefield > .arrowBottom::after {
  border-top-color: transparent !important;
}

.datefield > .arrowTop::before {
  border-bottom-color: transparent !important;
}

.datefield > .arrowTop::after {
  border-bottom-color: transparent !important;
}
