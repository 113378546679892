@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'dream-colors.css';

@font-face {
  font-family: 'clash-regular';
  src: url('../public/fonts/ClashDisplay-Regular.otf');
}

@font-face {
  font-family: 'clash-medium';
  src: url('../public/fonts/ClashDisplay-Medium.otf');
}

@font-face {
  font-family: 'clash-semibold';
  src: url('../public/fonts/ClashDisplay-Semibold.otf');
}

@font-face {
  font-family: 'clash-bold';
  src: url('../public/fonts/ClashDisplay-Bold.otf');
}

.font-clash-regular {
  font-family: 'clash-regular';
}

.font-clash-regular {
  font-family: 'clash-regular';
}

.font-clash-medium {
  font-family: 'clash-medium';
}

.font-clash-semibold {
  font-family: 'clash-semibold';
}

.font-clash-bold {
  font-family: 'clash-bold';
}

@layer base {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.ReactCrop {
  @apply relative inline-block overflow-hidden;
  cursor: crosshair;
}

.ReactCrop__child-wrapper {
  max-height: inherit;
}

.ReactCrop__crop-selection {
  @apply absolute cursor-move bg-transparent;
  box-shadow: 0 0 0 999999px rgba(0, 0, 0, 0.285);
}

.ReactCrop__drag-bar {
  /* add drag bar styles */
  @apply absolute bg-primary-500;
}

.ReactCrop__drag-bar.ord-n,
.ReactCrop__drag-bar.ord-s {
  @apply w-full;
  height: 1px;
}

.ReactCrop__drag-bar.ord-e,
.ReactCrop__drag-bar.ord-w {
  @apply h-full;
  width: 1px;
}

.ReactCrop__drag-bar.ord-n {
  top: 0;
  left: 0;
  cursor: ns-resize;
}

.ReactCrop__drag-bar.ord-e {
  top: 0;
  right: 0;
  cursor: ew-resize;
}

.ReactCrop__drag-bar.ord-s {
  bottom: 0;
  left: 0;
  cursor: ns-resize;
}

.ReactCrop__drag-bar.ord-w {
  top: 0;
  left: 0;
  cursor: ew-resize;
}

.ReactCrop__drag-handle {
  /* add drag handle styles */
  @apply absolute;
}

.ReactCrop__drag-handle.ord-nw,
.ReactCrop__drag-handle.ord-ne,
.ReactCrop__drag-handle.ord-se,
.ReactCrop__drag-handle.ord-sw {
  @apply w-2 h-2 bg-white border border-primary-500 rounded-sm;
}

.ReactCrop__drag-handle.ord-n,
.ReactCrop__drag-handle.ord-s,
.ReactCrop__drag-handle.ord-e,
.ReactCrop__drag-handle.ord-w {
  @apply bg-primary-500 rounded-sm;
}

.ReactCrop__drag-handle.ord-n,
.ReactCrop__drag-handle.ord-s {
  @apply w-full h-1 left-0 right-0 m-auto;
  max-width: 3rem;

  @container (max-width: 80px) {
    max-width: none;
  }
}

.ReactCrop__drag-handle.ord-e,
.ReactCrop__drag-handle.ord-w {
  @apply h-full w-1 top-0 bottom-0 m-auto;
  max-height: 3rem;

  @container (max-height: 80px) {
    max-height: none;
  }
}

.ReactCrop__drag-handle.ord-nw {
  cursor: nwse-resize;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
}

.ReactCrop__drag-handle.ord-ne {
  cursor: nesw-resize;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
}

.ReactCrop__drag-handle.ord-se {
  cursor: nwse-resize;
  bottom: 0;
  right: 0;
  transform: translate(50%, 50%);
}

.ReactCrop__drag-handle.ord-sw {
  cursor: nesw-resize;
  bottom: 0;
  left: 0;
  transform: translate(-50%, 50%);
}

.ReactCrop__drag-handle.ord-n {
  cursor: ns-resize;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  transform: translateY(-50%);
}

.ReactCrop__drag-handle.ord-e {
  cursor: ew-resize;
  bottom: 0;
  margin: auto;
  right: 0;
  top: 0;
  transform: translateX(50%);
}

.ReactCrop__drag-handle.ord-s {
  cursor: ns-resize;
  bottom: 0;
  left: 0;
  margin: auto;
  right: 0;
  transform: translateY(50%);
}

.ReactCrop__drag-handle.ord-w {
  cursor: ew-resize;
  bottom: 0;
  left: 0;
  margin: auto;
  top: 0;
  transform: translateX(-50%);
}

.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-n,
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-n.ord-s,
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-n.ord-w,
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-n.ord-e {
  @apply hidden;
}

.ReactCrop.is-tall {
  @apply h-full w-auto max-w-full;
}

.ReactCrop.is-tall .ReactCrop__child-wrapper {
  @apply h-full w-auto max-w-full;
}

.ReactCrop.is-tall img {
  @apply h-full w-auto max-w-full;
}

.ReactCrop.is-wide {
  @apply h-auto w-full max-h-full;
}

.ReactCrop.is-wide .ReactCrop__child-wrapper {
  @apply h-auto w-full max-h-full;
}

.ReactCrop.is-wide img {
  @apply h-auto w-full max-h-full;
}

.range-input {
  --from: 0;
  --to: 0;

  background-image: linear-gradient(
    to right,
    #e4e4e7 0%,
    #e4e4e7 calc(var(--from) * 1% - 0.1%),
    #ec4899 calc(var(--from) * 1%),
    #ec4899 calc(var(--to) * 1%),
    #e4e4e7 calc(var(--to) * 1% + 0.1%),
    #e4e4e7 100%
  );
}

input[type='range'].range-input::-webkit-slider-thumb {
  @apply appearance-none bg-white w-3.5 h-3.5 rounded-full border-solid border-2 border-gray-200;
}

input[type='range'].range-input:focus::-webkit-slider-thumb,
input[type='range'].range-input:active::-webkit-slider-thumb,
input[type='range'].range-input:hover::-webkit-slider-thumb {
  @apply border-primary-500;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
