.ProseMirror.comment-editor {
  p {
    @apply text-sm;
  }

  .is-editor-empty:before {
    @apply text-neutral-400 leading-none w-0 overflow-visible pointer-events-none whitespace-nowrap inline-block;
    content: attr(data-placeholder);
  }

  .mention-suggestion,
  .mention-suggestion-pending {
    @apply text-blue-500;
  }
}
