.layout {
  --content-width: minmax(auto, 640px);
  --margin-width: minmax(8px, 1fr);
  display: grid;
  grid-template-columns: [layout-start] var(--margin-width) [content-start] var(--content-width) [content-end] var(--margin-width) [layout-end];
  width: 100%;
}

.layout-content {
  grid-column: content;
}

.layout-comments {
  grid-column: content-end/layout-end;
}
