:root,
[data-theme='light'],
[data-mode='light'] {
  /* PRIMITIVE TOKENS */
  --neutral-1000: #0e0f13;

  --violet-25: #f8f6ff;

  /* Component Specific token */
  --color-text-primary: theme('colors.neutral.900');
  --color-text-primary-soft: theme('colors.neutral.800');
  --color-text-invert-primary: theme('colors.white');
  --color-text-secondary: theme('colors.neutral.400');
  --color-text-tertiary: theme('colors.neutral.300');
  --color-text-accent: theme('colors.violet.500');
  --color-text-on-accent: theme('colors.white');
  --color-text-success: theme('colors.emerald.400');
  --color-text-danger: theme('colors.rose.500');

  --color-background: theme('colors.white');
  --color-background-button-accent-soft: var(--violet-25);
  --color-background-secondary: theme('colors.neutral.100');
  --color-background-tertiary: theme('colors.neutral.200');
  --color-background-surface: theme('colors.neutral.300');
  --color-background-accent: theme('colors.violet.500');
  --color-background-accent-soft: theme('colors.violet.100');
  --color-background-modal: theme('colors.white');
  --color-background-overlay: #9b9aa080;
  --color-background-button-invert: var(--neutral-1000);
  --color-background-highlight: #25222e0a;
  --color-background-button-danger: theme('colors.rose.500');
  --color-background-button-danger-soft: theme('colors.rose.50');
  --color-background-success: theme('colors.emerald.400');
  --color-background-success-soft: theme('colors.emerald.50');
  --color-background-inverse: var(--neutral-1000);
  --color-background-inverse-secondary: theme('colors.neutral.900');

  --color-border: theme('colors.neutral.200');
  --color-border-darken: #10182833;
  --color-border-accent: theme('colors.violet.400');
  --color-border-accent-soft: theme('colors.indigo.50');
  --color-border-soft: theme('colors.neutral.100');
  --color-border-danger: theme('colors.rose.500');
  --color-border-neutral: theme('colors.neutral.800');
  --color-border-button: theme('colors.neutral.300');

  --color-hr: theme('colors.neutral.200');

  --shadow-xs: 0px 1px 2px rgba(16, 24, 40, 0.05);
  --shadow-sm: 0px 1px 5px rgba(16, 24, 40, 0.07), 0px 1px 2px rgba(16, 24, 40, 0.04);
  --shadow-md: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  --shadow-lg: 0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05);
  --shadow-xl: 0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  --shadow-xl-soft: 0px 15px 15px -8px rgba(16, 24, 40, 0.04), 0px 40px 48px -8px rgba(16, 24, 40, 0.1);
}

[data-theme='dark'],
[data-mode='dark'] {
  /* Component Specific token */
  --color-text-primary: theme('colors.white');
  --color-text-primary-soft: theme('colors.neutral.100');
  --color-text-invert-primary: theme('colors.neutral.900');
  --color-text-secondary: theme('colors.neutral.400');
  --color-text-tertiary: theme('colors.neutral.600');
  --color-text-accent: theme('colors.violet.500');
  --color-text-on-accent: theme('colors.white');
  --color-text-success: theme('colors.emerald.400');
  --color-text-danger: theme('colors.rose.500');

  --color-background: var(--neutral-1000);
  --color-background-button-accent-soft: theme('colors.violet.900');
  --color-background-secondary: theme('colors.neutral.900');
  --color-background-tertiary: theme('colors.neutral.800');
  --color-background-surface: theme('colors.neutral.700');
  --color-background-accent: theme('colors.violet.500');
  --color-background-accent-soft: theme('colors.violet.800');
  --color-background-modal: var(--neutral-1000);
  --color-background-overlay: #00000080;
  --color-background-button-invert: theme('colors.white');
  --color-background-highlight: #e9e9ff0a;
  --color-background-button-danger: theme('colors.rose.400');
  --color-background-button-danger-soft: theme('colors.rose.950');
  --color-background-success: theme('colors.emerald.400');
  --color-background-success-soft: theme('colors.emerald.950');
  --color-background-inverse: theme('colors.white');
  --color-background-inverse-secondary: theme('colors.neutral.50');

  --color-border: theme('colors.neutral.800');
  --color-border-soft: theme('colors.neutral.900');
  --color-border-accent-soft: theme('colors.indigo.900');
  --color-border-neutral: theme('colors.neutral.100');
  --color-border-button: theme('colors.neutral.700');

  --color-hr: theme('colors.neutral.800');
}
