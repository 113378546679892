.wb-react-colorful-input-wrapper {
  flex-grow: 1;
  display: flex;
  align-items: center;
  height: 100%;
}

.wb-react-colorful-input-wrapper > input {
  background: none;
  border: none;
  color: currentColor;
  flex: 1;
  outline: none;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1;
  padding: 0;
  max-width: 63px;
}

.wb-react-colorful-input-wrapper > input:focus {
  outline: none;
}

.wb-react-colorful {
  border-radius: 10px;
  user-select: none;
}

.wb-react-colorful .react-colorful {
    max-width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 18rem;
}

.wb-react-colorful .react-colorful__saturation {
    border-radius: 8px;
  }

.wb-react-colorful .react-colorful__saturation-pointer {
    width: 1rem;
    height: 1rem;
    cursor: grab;
  }

.wb-react-colorful .react-colorful__saturation-pointer:active {
    cursor: grabbing;
  }

.wb-react-colorful .react-colorful__hue {
    border-radius: 100px;
    height: 0.5rem;
  }

.wb-react-colorful .react-colorful__hue-pointer {
    width: 1rem;
    height: 1rem;
    cursor: grab;
  }

.wb-react-colorful .react-colorful__hue-pointer:active {
    cursor: grabbing;
  }

.wb-react-colorful .react-colorful__alpha {
    border-radius: 100px;
    height: 0.5rem;
  }

.wb-react-colorful .react-colorful__alpha-pointer {
    width: 1rem;
    height: 1rem;
    cursor: grab;
  }

.wb-react-colorful .react-colorful__alpha-pointer:active {
    cursor: grabbing;
  }